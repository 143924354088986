import { ProductTag } from 'shared-types'

export const filterProductTags = (
  tags: ProductTag[],
  isMarketplaceProduct: boolean
) => {
  return tags.filter((tag) => {
    const isMarketplaceTag = tag.desc?.toLowerCase().includes('marketplace')

    return (isMarketplaceTag && isMarketplaceProduct) || !isMarketplaceTag
  })
}
