import { dehydrate, QueryClient } from '@tanstack/react-query'
import { GetServerSideProps, GetServerSidePropsContext } from 'next'

import { Page } from '../components/Page'
import { Client } from '../customClients/client'
import { HTTPStatus } from '../customClients/httpStatus'
import { checkProtectedRoute } from '../helpers/checkProtectedRoute'
import { authOptions } from '../helpers/nextAuth/config'
import { ServerSideAuthenticator } from '../helpers/serverSideAuthenticator'

export const getServerSideProps: GetServerSideProps = async (
  ctx: GetServerSidePropsContext
) => {
  const { resolvedUrl } = ctx
  ctx.res.setHeader('Cache-Control', 'private, stale-while-revalidate=3')

  const queryClient = new QueryClient()
  const urlParts = resolvedUrl.split('?')
  const urlSlug = urlParts?.[0]

  const searchParams = urlParts?.[1] ? urlParts?.[1] : ''
  const params = searchParams?.split('&')
  let token = params?.find((param) => {
    return param.startsWith('token')
  })

  const preview = params?.find((param) => {
    return param.startsWith('live_preview')
  })

  const authenticator = new ServerSideAuthenticator(ctx, authOptions)
  if (urlSlug === '/recoverypassword' && token) {
    token = token.split('=')?.[1]

    return {
      redirect: {
        destination: `/?token=${token}#reset-password`,
        permanent: false,
      },
      props: {},
    }
  }

  const filteredSelectedParams = params
    ?.filter((p) => {
      return (
        !p.startsWith('utm') &&
        !p.startsWith('gclid') &&
        !p.startsWith('sc_') &&
        !p.startsWith('token')
      )
    })
    ?.join('&')

  const domain = ctx.req.headers['x-forwarded-host'] || ctx.req.headers.host

  const queryString = `slug=${urlSlug}&domain=${domain}&${filteredSelectedParams}`

  const session = await authenticator.getSession()
  queryClient.setQueryData(['session'], session)

  let pageResult = await Client.cms.getPage({
    queryClient,
    queryString,
    session,
  })
  let page

  // Redirect to Home with Redirect URL when user Logs out and is on a Protected Route
  const isProtectedRoute = checkProtectedRoute(urlSlug)

  let isLoggedIn = false

  if (session) {
    isLoggedIn = !session?.token?.anonymousId
  }

  if (isProtectedRoute && !isLoggedIn) {
    return {
      redirect: {
        destination: `/?redirectUrl=${urlSlug}#login`,
        permanent: false,
      },
      props: {},
    }
  }

  if (pageResult.getErrorSafe()) {
    const error = pageResult.getErrorSafe()
    if (error.status === HTTPStatus.Unauthorized) {
      const session = await authenticator.refreshToken()
      pageResult = await Client.cms.getPage({
        queryClient,
        queryString,
        session,
      })
    }
  }

  if (pageResult.getErrorSafe()) {
    const error = pageResult.getErrorSafe()
    page = pageResult?.getError()?.body?.data?.page

    if (
      error.status === HTTPStatus.TemporaryRedirect ||
      error.status === HTTPStatus.PermanentRedirect
    ) {
      return {
        redirect: {
          destination: error.body?.data?.redirect?.url,
          permanent: error.status === HTTPStatus.PermanentRedirect,
        },
      }
    }
    if (error.status === HTTPStatus.InternalServerError) {
      console.error('error', error)
      throw new Error(error.message)
    }
    if (error.status === HTTPStatus.NotFound) {
      ctx.res.statusCode = 404
    }
  } else {
    page = pageResult?.getValueSafe()?.data?.page
  }

  if (!page) {
    return {
      notFound: true,
    }
  }

  const dehydratedState =
    pageResult?.getValueSafe()?.data?.dehydratedState?.length || session
      ? dehydrate(queryClient)
      : {}

  page.brand = process.env.BRAND || ''
  page._vsfClient = process.env.BRAND_VSF_CLIENT || ''

  return {
    props: {
      dehydratedState,
      page,
      key: urlSlug,
      domain,
      gtmId: pageResult?.getValueSafe()?.data?.gtmId || null,
      bazaarVoice: pageResult?.getValueSafe()?.data?.bazaarVoice || {},
      enabledPaymentMethods:
        pageResult?.getValueSafe()?.data?.enabledPaymentMethods || [],
    },
  }
}

export default Page
