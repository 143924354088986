import classNames from 'classnames'
import { Image } from '~/components/Image'
import { getImageSrc, getImageSrcSet } from '~/helpers'
import ImageOverlayLinks from '../../components/ImageOverlayLinks/ImageOverlayLinks'
import styles from './HeroBanner.module.css'
import { BackgroundImageProps } from './HeroBanner.types'

const HeroBannerBackgroundImage = ({
  desktop,
  tablet,
  mobile,
  renderLinks,
}: BackgroundImageProps) => {
  const mobileImage = mobile || tablet || desktop
  const tabletImage = tablet || desktop || mobile
  const desktopImage = desktop || tablet || mobile

  return (
    <>
      {desktopImage?.src && (
        <>
          {renderLinks && (
            <ImageOverlayLinks
              desktopImage={desktopImage}
              tabletImage={tabletImage}
              mobileImage={mobileImage}
            />
          )}
          <Image
            sizes='(min-width: 1024px) 100vw, 0px'
            className={classNames('w-full h-full', styles.imageContainer)}
            src={getImageSrc(desktopImage.src, '')}
            addSrcSet={false}
            alt={desktopImage.altText}
            width={desktopImage.width}
            height={desktopImage.height}
            priority
            style={{ objectFit: 'cover' }}
            sources={
              <>
                <source
                  srcSet={getImageSrcSet(mobileImage?.src, [
                    {
                      intrinsicImageSize: '800',
                    },
                  ])}
                  media='(max-width: 768px)'
                />
                <source
                  srcSet={getImageSrcSet(tabletImage?.src, [
                    {
                      intrinsicImageSize: '1100',
                    },
                  ])}
                  media='(max-width: 1024px)'
                />
              </>
            }
          />
        </>
      )}
      {!desktopImage?.src && null}
    </>
  )
}

export default HeroBannerBackgroundImage
