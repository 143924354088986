import classNames from 'classnames'
import RichText from '~/components/RichText/RichText'
import { SalePrice } from '~/components/SalePrice'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import styles from './HeroBanner.module.css'
import { HeroBannerTextContentProps } from './HeroBanner.types'

const richTextOptions = (textColor: string) => {
  return {
    customElementTypes: {
      p: (_attrs, children) => {
        return (
          <Typography
            variant={TypographyVariant.BodyLarge}
            tag={TypographyTag.p}
            className={classNames(textColor, styles.paragraph)}>
            {children}
          </Typography>
        )
      },
      h6: (_attrs, children) => {
        return (
          <Typography
            variant={TypographyVariant.BodyXLarge}
            tag={TypographyTag.p}
            className={classNames(textColor, styles.paragraph)}>
            {children}
          </Typography>
        )
      },
    },
  }
}

export const HeroBannerTextContent = ({
  title,
  paragraph,
  price,
  label,
  priceText,
  saveMessage,
}: HeroBannerTextContentProps) => {
  const btnTextColor = '!text-white'
  const textColor = '!text-greyscale-50'

  return (
    (title || paragraph) && (
      <div className={classNames('w-full h-full flex flex-col', 'gap-2')}>
        {label && (
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodySmall}
            className={classNames('uppercase', textColor, styles.label)}>
            {label}
          </Typography>
        )}
        {title && (
          <div
            className={classNames(
              'w-full px-12 flex justify-center py-4',
              textColor,
              styles.title
            )}>
            <RichText
              content={title.content}
              options={richTextOptions(textColor)}
            />
          </div>
        )}
        {paragraph && (
          <div className={classNames('w-full px-12 flex justify-center pb-4')}>
            <RichText
              content={paragraph.content}
              options={richTextOptions(textColor)}
            />
          </div>
        )}
        {price && (
          <SalePrice
            price={price}
            regularPriceClassName={classNames(
              btnTextColor,
              styles.regularPriceLabel
            )}
            savePriceClassName={classNames(styles.saveUpToButton)}
            saveUpToButtonStyle={{
              backgroundColor: saveMessage?.backgroundColor,
              textColor: saveMessage?.textColor,
            }}
            finalPriceClassName={btnTextColor}
            wasText={priceText}
            saveText={saveMessage?.text}
            truncateDecimalZeros
          />
        )}
      </div>
    )
  )
}
