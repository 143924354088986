/* eslint-disable camelcase */
import { useEffect, useMemo, useRef } from 'react'
import { Link, VisuallyHidden, useIsVisible } from '@overdose/components'
import classNames from 'classnames'

import { ProductImage } from '~/components'
import { Checkbox } from '~/components/Checkbox'
import { ProductCard } from '~/components/ProductCard'
import { Stack } from '~/components/Stack'
import Tag from '~/components/Tag'
import { filterProductTags } from '~/helpers'
import { useCompareProducts } from '~/hooks'
import { GTM, GTMEvent } from '~/lib'
import { PromoCard } from '../PromoCard'
import { FeaturedProductCardProps } from './FeaturedProductCard.types'

export const FeaturedProductCard = ({
  image,
  finalPrice,
  regularPrice,
  title,
  url,
  sku,
  showAddToCartButton = false,
  className,
  tags = [],
  brandName,
  category,
  index,
  imagePriority = false,
  promotionId,
  promotionName,
  creativeName,
  creativeSlot,
  category1Name,
  category2Name,
  category3Name,
  category4Name,
  category5Name,
  item_status_tags,
  listId,
  listName,
  availableForDelivery,
  inStockText,
  rating,
  bgColor,
  displayTitle,
  isFullWidth,
  inStock,
  inStore,
  columnSpan,
  isMarketplaceProduct,
}: FeaturedProductCardProps) => {
  const { isProductInCompare, toggleProduct, canAddRemoveToCompare } =
    useCompareProducts()
  const ref = useRef()
  const isVisible = useIsVisible(ref)

  const product = useMemo(() => {
    return {
      title,
      sku,
      finalPrice,
      category,
      category1Name,
      category2Name,
      category3Name,
      category4Name,
      category5Name,
      item_status_tags,
      listId,
      listName,
      brandName,
      url,
    }
  }, [
    brandName,
    category,
    category1Name,
    category2Name,
    category3Name,
    category4Name,
    category5Name,
    finalPrice,
    item_status_tags,
    listId,
    listName,
    sku,
    title,
    url,
  ])
  const isPromotion =
    !!promotionId && !!promotionName && !!creativeName && !!creativeSlot

  const filteredTags = useMemo(() => {
    return filterProductTags(tags, isMarketplaceProduct)
  }, [tags, isMarketplaceProduct])

  useEffect(() => {
    const payload = {
      promotionId,
      promotionName,
      creativeName,
      creativeSlot,
      product,
      index,
    }
    if (isVisible && isPromotion) {
      GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
      GTM.dispatch(GTMEvent.VIEW_PROMOTION, payload)
    }
  }, [
    brandName,
    category,
    category1Name,
    category2Name,
    category3Name,
    category4Name,
    category5Name,
    creativeName,
    creativeSlot,
    finalPrice,
    index,
    isPromotion,
    isVisible,
    item_status_tags,
    listId,
    listName,
    product,
    promotionId,
    promotionName,
    sku,
    title,
  ])

  if (bgColor && isFullWidth) {
    return (
      <PromoCard
        action={{
          href: url,
          title: 'Learn more',
          accessibleTitle: 'Learn more',
        }}
        backgroundColor={bgColor}
        backgroundImage={image}
        title={title}
        description={displayTitle}
        tags={filteredTags}
        textColor='light'
        backgroundPosition={{
          x: 'center',
          y: 'bottom',
        }}
        backgroundSize='contain'
        index={index}
        promotionId={promotionId}
        promotionName={promotionName}
        creativeName={creativeName}
        creativeSlot={creativeSlot}
        className={columnSpan === 1 ? 'flex-col' : ''}
      />
    )
  }

  const isThreeColumnSpan = columnSpan === Number('3')

  return (
    <div
      className={classNames(
        'relative w-full h-[432px] lg:h-full rounded-md overflow-hidden',
        className
      )}
      ref={ref}>
      {image && (
        <ProductImage
          src={image?.src}
          alt={image.altText}
          width={image.width}
          height={image.height}
          className='object-cover w-full h-full'
          sizes={
            isThreeColumnSpan
              ? '(min-width: 1536px) 50vw, (min-width: 1366px) 60vw, (min-width: 1024px) 75vw, 100vw'
              : '(max-width: 1365px) 339px, (max-width: 1365px) 290px, (max-width: 1023px) 490px, 280px'
          }
          priority={imagePriority}
        />
      )}

      <div className='p-4 lg:p-8'>
        {url && (
          <Link
            title={`${title} details`}
            className='absolute inset-0'
            to={url}>
            <VisuallyHidden>{title} details</VisuallyHidden>
          </Link>
        )}

        <div className='absolute left-4 bottom-4 lg:left-6 pr-4 lg:pr-6 lg:bottom-6 max-w-[360px] mt-auto font-body-regular-font-family'>
          <Stack direction='horizontal' spacing='xs'>
            {filteredTags.map(
              ({ variant, desc, backgroundColor, color }, index) => {
                return (
                  <Tag
                    theme={{ tag: 'h-6' }}
                    key={index}
                    variant={variant}
                    backgroundColor={backgroundColor}
                    color={color}
                    children={
                      <span className='uppercase not-italic text-[10px]/[14px]'>
                        {desc}
                      </span>
                    }
                  />
                )
              }
            )}
          </Stack>
          <ProductCard
            className='bg-white rounded-md mt-2 max-w-[164px] md:max-w-[336px]'
            finalPrice={finalPrice}
            regularPrice={regularPrice}
            title={title}
            url={url}
            showAddToCartButton={showAddToCartButton}
            sku={sku}
            brandName={brandName}
            isFeatured
            isShowCheck={false}
            availableForDelivery={availableForDelivery}
            inStockText={inStockText}
            promotionId={promotionId}
            promotionName={promotionName}
            creativeName={creativeName}
            creativeSlot={creativeSlot}
            category={category}
            category1Name={category1Name}
            category2Name={category2Name}
            category3Name={category3Name}
            category4Name={category4Name}
            category5Name={category5Name}
            item_status_tags={item_status_tags}
            listId={listId}
            listName={listName}
            noImage
            rating={rating}
            inStock={inStock}
            inStore={inStore}
            titleSection='Featured Product'
            showCompactAddToCart={false}
          />
        </div>

        <div className='absolute top-4 right-4'>
          <Checkbox
            label=''
            name={sku}
            size='lg'
            defaultChecked={false}
            disabled={!canAddRemoveToCompare(sku)}
            checked={isProductInCompare(sku)}
            onChange={() => {
              toggleProduct(product)
            }}
          />
        </div>
      </div>
    </div>
  )
}
