import React, {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useScrollLock, VisuallyHidden } from '@overdose/components'
import {
  IconBuildingStore,
  IconChevronDown,
  IconMapPin,
  IconMenu2,
  IconPlaylistAdd,
  IconSearch,
  IconShoppingBag,
  IconShoppingCart,
  IconUser,
} from '@tabler/icons-react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Button, LoadingSpinner } from '~/components'
import {
  navigationCardData1,
  navigationCardData2,
  navigationCardData3,
} from '~/components/AccountQuickViewDrawer/AccountQuickViewDrawer.mock'
import { HintTooltip } from '~/components/HintTooltip'
import { Image } from '~/components/Image'
import Input from '~/components/Input'
import { SectionContainer } from '~/components/SectionContainer'
import SitewideBanner from '~/components/SitewideBanner/SitewideBanner'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import {
  useCart,
  useCartDrawer,
  useCustomer,
  useCustomerListsDrawer,
  useDebounce,
  usePage,
  useSearchResults,
  useUserDeliveryPostcode,
} from '~/hooks'
import { useUserPreferredStore } from '~/hooks/useUserPreferredStore'
import { MIN_DESKTOP_WIDTH, MIN_TABLET_WIDTH } from '~/providers/PageProvider'
import jaycarLogo from '~/public/images/jaycar-logo.svg'
import rtmLogo from '~/public/images/rtm-logo.svg'
import styles from './Header.module.css'
import { HeaderProps } from './Header.types'

const Badge = dynamic(
  async () => {
    const mod = await import('@overdose/components')
    return mod.Badge
  },
  {
    ssr: false,
    loading: () => {
      return <IconShoppingBag />
    },
  }
)

const SearchResults = dynamic(
  async () => {
    const mod = await import('~/components/SearchResults')
    return mod.SearchResults
  },
  { ssr: false }
)

const CartDrawer = dynamic(
  async () => {
    const mod = await import('~/components/CartDrawer')
    return mod.CartDrawer
  },
  { ssr: false }
)

const MegaMenu = dynamic(
  async () => {
    const mod = await import('~/components/MegaMenu')
    return mod.MegaMenu
  },
  { ssr: false }
)

const AccountQuickViewDrawer = dynamic(
  async () => {
    const mod = await import('~/components/AccountQuickViewDrawer')
    return mod.AccountQuickViewDrawer
  },
  { ssr: false }
)

const headerLinks = {
  loyaltyLink: {
    href: '/account/rewards',
    title: 'View all',
  },
  ordersLink: {
    href: '/account/orders',
    title: 'View all',
  },
  accountsInfoLink: {
    href: '/account/dashboard',
    title: 'View all',
  },
}

const DEBOUNCE_DELAY = 300
const whiteColor = 'var(--color-grayscale-white)'
const iconButtonStyles = {
  '--btn-size-md-height': '32px',
  '--btn-ghost-color-text-primary': whiteColor,
}

const navIconButtonStyles = {
  '--btn-ghost-text-decoration': 'none',
  '--btn-size-lg-padding': 0,
}

const headerDrawerBtnStyles = {
  '--btn-ghost-text-decoration': 'none',
  '--btn-ghost-color-text-primary': whiteColor,
  '--btn-font-weight': 'var(--typography-body-regular-font-weight)',
  '--btn-icon-size': '18',
  '--btn-font-size': 'var(--typography-body-small-font-size)',
  '--btn-icon-spacing': '4px',
  '--btn-size-md-height': '34px',
}

const HeaderComponent: FC<HeaderProps> = ({
  links,
  megaMenu,
  width = 'full',
  payLaterProviders,
  homePageH1,
  sitewideBanner,
  specials,
  searchQuery: searchQueryFromUrl,
}: HeaderProps) => {
  const { store, promptUserToSelectStore } = useUserPreferredStore()
  const { openDrawer } = useCustomerListsDrawer()
  const { customer } = useCustomer()
  const router = useRouter()
  const isHomePage = router?.asPath?.split('#')[0] === '/'
  const [openAccountQuickViewer, setOpenAccountQuickViewer] = useState(false)
  const [openMegaMenu, setOpenMegaMenu] = useState(false)
  const [searchQuery, setSearchQuery] = useState(searchQueryFromUrl || '')
  const [isOpen, setIsOpen] = useState(false)
  const { cart } = useCart()
  const debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCE_DELAY)
  const { searchResults, isLoadingSearchResults } = useSearchResults(
    debouncedSearchQuery,
    isOpen
  )
  const { postcode, promptUserPostcodeDrawer } = useUserDeliveryPostcode() || {}
  const inputRef = useRef<HTMLInputElement>(null)
  const searchRef = useRef<HTMLInputElement>(null)
  const page = usePage()

  useEffect(() => {
    const listener = (e) => {
      if (!searchRef.current || searchRef.current.contains(e.target)) {
        return
      }
      e.target.className &&
      typeof e.target.className.includes !== 'undefined' &&
      e.target.className.includes('Base-Input')
        ? setIsOpen(e.target.className.includes('Base-Input'))
        : setIsOpen(false)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [])

  useScrollLock(isOpen)

  const handleAccountViewer = useCallback(() => {
    if (customer) {
      setOpenAccountQuickViewer(true)
    } else {
      router.push({ hash: 'login' })
    }
  }, [customer, router])

  const onSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (searchQuery) {
      router.push(`/search?q=${encodeURIComponent(searchQuery)}`)
    }
  }

  useEffect(() => {
    const handleRouteChangeEnd = () => {
      setIsOpen(false)
      if (inputRef?.current) {
        inputRef.current.blur()
      }
    }
    document.querySelector('html').classList.add('scroll-padTop')
    router.events.on('routeChangeComplete', handleRouteChangeEnd)
    return () => {
      document.querySelector('html').classList.remove('scroll-padTop')
      router.events.off('routeChangeComplete', handleRouteChangeEnd)
    }
  }, [router.events])

  const { openCartDrawer } = useCartDrawer()

  const renderLogo = () => {
    return (
      <>
        {/* JayCar */}
        <Image
          src={jaycarLogo}
          alt='Jaycar Logo'
          width={148}
          height={40}
          className={classNames('hidden', styles.jaycarLogoDesktop)}
        />
        <Image
          src={jaycarLogo}
          alt='Jaycar Logo'
          width={112}
          height={30}
          className={classNames('hidden', styles.jaycarLogoMobile)}
        />

        {/* RTM */}
        <Image
          src={rtmLogo}
          alt='RTM Logo'
          width={148}
          height={40}
          className={classNames('hidden', styles.rtmLogoDesktop)}
        />
        <Image
          src={rtmLogo}
          alt='RTM Logo'
          width={112}
          height={30}
          className={classNames('hidden', styles.rtmLogoMobile)}
        />
      </>
    )
  }

  const renderSearchBar = (idSuffix: string) => {
    return (
      <form onSubmit={onSearchSubmit} className='grow'>
        <Input
          theme={{
            root: classNames(styles.searchInput, 'px-4 bg-white'),
            input: 'z-10 text-lg leading-6',
          }}
          type='text'
          name={`search_${idSuffix}`}
          placeholder='Search products, brands and more...'
          autoComplete='off'
          prefix={
            <IconSearch size={20} color='var(--color-typography-heading)' />
          }
          value={searchQuery}
          inputref={inputRef}
          onKeyDown={(e) => {
            if (e?.key === 'Escape') {
              e.currentTarget?.blur()
              setIsOpen(false)
            }
          }}
          onChange={(e) => {
            setSearchQuery(e)
          }}
          onFocus={() => {
            setOpenMegaMenu(false)
            setIsOpen(true)
          }}
        />
      </form>
    )
  }

  useEffect(() => {
    let scrollTimerId: number
    const headerEl = document.getElementsByTagName('header')?.item(0)
    const pdpHeaderEl = document
      .getElementsByClassName('productPageHeader')
      ?.item(0)

    const handleScroll = () => {
      if (
        (window.innerWidth > MIN_DESKTOP_WIDTH &&
          window.innerHeight > Number('500')) ||
        screen.orientation.type.includes('portrait')
      ) {
        return
      }

      if (scrollTimerId) {
        clearTimeout(scrollTimerId)
      }

      scrollTimerId = window.setTimeout(() => {
        headerEl?.classList.remove('opacity-0')
        pdpHeaderEl?.classList?.remove('!top-0')
      }, Number('500'))

      if (window.scrollY > page.pageHeaderHeight) {
        headerEl?.classList.add('opacity-0')
        pdpHeaderEl?.classList?.add('!top-0')
        return
      }

      headerEl?.classList.remove('opacity-0')
      pdpHeaderEl?.classList?.remove('!top-0')
    }

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [page.pageHeaderHeight])

  const headerStyle = useMemo(() => {
    const style: React.CSSProperties = {}

    if (page.pageWidth >= MIN_DESKTOP_WIDTH) {
      style.top = '0'
    } else {
      style.top = `-${
        page.sitewideBannerHeight + page.pageHeaderMobileDrawerHeight
      }px`
    }

    return style
  }, [
    page.pageWidth,
    page.sitewideBannerHeight,
    page.pageHeaderMobileDrawerHeight,
  ])

  const getSearchResultContainerStyle = useCallback(() => {
    if (page.pageWidth < MIN_TABLET_WIDTH) {
      return {
        top: `${page.pageHeaderHeight}px`,
      } as React.CSSProperties
    }

    const headerFormEl = page.getHeaderFormEl()
    const { top } = headerFormEl.getBoundingClientRect()
    const { height } = headerFormEl.getBoundingClientRect()

    return {
      top: `${height + top}px`,
    } as React.CSSProperties
  }, [page])

  const openPostcodeDrawer = () => {
    setOpenMegaMenu(false)
    promptUserPostcodeDrawer(
      page.head?.product?.sku,
      Number(page.head?.product?.offer?.price) / 100
    )
  }

  const openStoreDrawer = () => {
    setOpenMegaMenu(false)
    promptUserToSelectStore()
  }

  return (
    <SectionContainer
      wrapperRef={page.pageHeaderRef}
      width={width}
      wrapperStyle={headerStyle}
      wrapperClassName={classNames(styles.header, {
        '!z-[1002]': isOpen,
      })}
      tag='header'>
      {sitewideBanner && <SitewideBanner {...sitewideBanner} />}
      <div
        className={classNames(
          styles.headerBackground,
          styles.headerBorder,
          'mobileHeaderDrawer flex justify-between lg:hidden'
        )}>
        <Button
          variant='ghost'
          style={headerDrawerBtnStyles}
          icon={<IconBuildingStore size={18} className='mr-1' />}
          onClick={openStoreDrawer}>
          {store?.displayName || 'Select Store'}
          <IconChevronDown size={14} />
        </Button>
        <Button
          variant='ghost'
          style={headerDrawerBtnStyles}
          icon={<IconMapPin size={18} />}
          onClick={openPostcodeDrawer}>
          {postcode || 'Postcode'}
          <IconChevronDown size={14} />
        </Button>
      </div>
      <div
        className={classNames(
          'headerForm',
          styles.simpleHeader,
          styles.headerBackground
        )}>
        <div className='flex items-center justify-between gap-x-10'>
          <div className={classNames(styles.logoWrapper, 'gap-6')}>
            <div className='lg:hidden block'>
              <Button
                style={iconButtonStyles}
                variant='ghost'
                title='Main Menu'
                shape='circle'
                icon={<IconMenu2 />}
                iconOnly
                onClick={() => {
                  return setOpenMegaMenu((prev) => {
                    return !prev
                  })
                }}>
                <VisuallyHidden>Main menu</VisuallyHidden>
              </Button>
            </div>

            {isHomePage && (
              <h1>
                {renderLogo()}
                <VisuallyHidden>{homePageH1}</VisuallyHidden>
              </h1>
            )}

            {!isHomePage && (
              <Link title='Go to home page' href='/'>
                {renderLogo()}
                <VisuallyHidden>Jaycar</VisuallyHidden>
              </Link>
            )}
            <div className='hidden lg:block grow'>
              {renderSearchBar('desktop')}
            </div>
          </div>
          <div
            className={classNames(
              styles.iconWrapper,
              'text-white flex gap-2.5 lg:gap-6 2xl:gap-8'
            )}>
            <HintTooltip message='My Account'>
              <Button
                icon={<IconUser size={32} />}
                iconOnly
                onClick={() => {
                  setOpenMegaMenu(false)
                  handleAccountViewer()
                }}
                shape='circle'
                style={{
                  '--btn-icon-size': '32px',
                  ...iconButtonStyles,
                }}
                variant='ghost'>
                <VisuallyHidden>My Account</VisuallyHidden>
              </Button>
            </HintTooltip>
            <HintTooltip message='My Lists'>
              <Button
                icon={<IconPlaylistAdd size={32} />}
                iconOnly
                onClick={() => {
                  setOpenMegaMenu(false)
                  if (customer) {
                    openDrawer('')
                  } else {
                    router.push({ hash: 'login' })
                  }
                }}
                shape='circle'
                style={{
                  '--btn-icon-size': '32px',
                  ...iconButtonStyles,
                }}
                variant='ghost'>
                <VisuallyHidden>My Lists</VisuallyHidden>
              </Button>
            </HintTooltip>
            <HintTooltip message='Cart'>
              <Button
                tooltip='Cart'
                data-id='viewCartButton'
                icon={
                  <Badge
                    count={cart?.totalLineItemQuantity}
                    variant='count'
                    isRounded
                    theme={{ root: styles.countBadge, number: 'w-6 h-6' }}>
                    <IconShoppingCart size={32} />
                  </Badge>
                }
                iconOnly
                onClick={() => {
                  setOpenMegaMenu(false)
                  openCartDrawer()
                }}
                shape='circle'
                style={{
                  '--btn-icon-size': '32px',
                  ...iconButtonStyles,
                }}
                variant='ghost'>
                <VisuallyHidden>Cart</VisuallyHidden>
              </Button>
            </HintTooltip>
          </div>
        </div>
        <div className='lg:!hidden grow mt-2'>{renderSearchBar('mobile')}</div>
      </div>

      <nav className={styles.menuHeader}>
        <div className='flex items-center gap-9'>
          <Button
            style={navIconButtonStyles}
            size='lg'
            variant='ghost'
            icon={<IconMenu2 />}
            onClick={() => {
              return setOpenMegaMenu(true)
            }}>
            Shop Products
          </Button>

          <div className='hidden xl:block border-r border-[var(--header-color-separator)] h-6' />
          {links?.length > 0 &&
            links.map((link) => {
              return (
                <Link
                  href={link.href}
                  key={link.title}
                  className='py-4 hidden xl:block'
                  title={`View ${link.title}`}>
                  <Typography
                    tag={TypographyTag.p}
                    variant={TypographyVariant.BodyLargeBold}
                    className='text-primary-heading'>
                    {link.title}
                  </Typography>
                  <VisuallyHidden>View {link.title}</VisuallyHidden>
                </Link>
              )
            })}
        </div>
        <div className='flex gap-9'>
          <Button
            style={navIconButtonStyles}
            size='lg'
            variant='ghost'
            icon={<IconMapPin />}
            onClick={openPostcodeDrawer}>
            <span className='flex flex-col items-start'>
              <Typography
                tag={TypographyTag.span}
                variant={TypographyVariant.BodySmall}>
                Deliver to
              </Typography>
              {postcode || 'Postcode'}
            </span>
          </Button>
          <Button
            style={navIconButtonStyles}
            size='lg'
            variant='ghost'
            icon={<IconBuildingStore />}
            onClick={openStoreDrawer}>
            <span className='flex flex-col items-start'>
              <Typography
                tag={TypographyTag.span}
                variant={TypographyVariant.BodySmall}>
                Your store
              </Typography>
              {store?.displayName || 'Select Store'}
            </span>
          </Button>
        </div>
      </nav>

      {isOpen && (
        <>
          <div
            className='absolute left-0 bg-white w-full z-10 search-result-container top-[10.5rem]'
            style={getSearchResultContainerStyle()}
            ref={searchRef}>
            {isLoadingSearchResults && (
              <div className='absolute inset-0 flex items-center justify-center z-20 backdrop-blur'>
                <LoadingSpinner />
              </div>
            )}
            <SearchResults
              {...searchResults}
              specials={specials}
              debouncedSearchQuery={debouncedSearchQuery}
              isOpen={isOpen}
            />
          </div>
          <div
            style={getSearchResultContainerStyle()}
            className={styles.backdrop}
          />
        </>
      )}

      <AccountQuickViewDrawer
        open={openAccountQuickViewer}
        onClose={() => {
          setOpenAccountQuickViewer(false)
        }}
        navData={[
          navigationCardData1,
          navigationCardData2,
          navigationCardData3,
        ]}
        headerLinks={headerLinks}
      />
      <CartDrawer payLaterProviders={payLaterProviders} />
      <MegaMenu
        isOpen={openMegaMenu}
        categories={page.primaryNavigation}
        {...megaMenu}
        onClose={() => {
          return setOpenMegaMenu(false)
        }}
      />
    </SectionContainer>
  )
}

export const Header: FC<HeaderProps> = (props) => {
  return <HeaderComponent {...props} />
}
